/**
 * Установка специальных опций для селекта
 *
 * @see {@link https://react-select.com/home}
 * @return {Object}
 */
export const selectStyles = {
  control: provided => ({
    ...provided,
    outline: "initial",
  }),
  input: () => ({
    margin: 0,
    padding: 0,
  }),
};

/**
 * Отображение текста при отсутствии результата поиска
 *
 * @see {@link https://react-select.com/home}
 * @return {String}
 */
export const noOptionsText = () => null;
