import React from "react";
import ReactDOM from "react-dom/client";

import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import App from "./App.jsx";

import "./assets/scss/base/style.scss";

const app = ReactDOM.createRoot(document.getElementById("app"));

app.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);
