import React from "react";
import Button from "../../components/Button/Button.jsx";

import WrapperContainer from "../../components/WrapperContainer/WrapperContainer.jsx";
import {intl} from "../../locale";
import {IS_UFANET_COMPANY} from "../../utils/consts.js";

const Error = () => {
  return (
    <div className="auth-portal-content">
      <div className="auth-portal-content__title">
        {intl.formatMessage({id: "modalError.title"})}
      </div>
      <div className="auth-portal-content__content">
        <WrapperContainer gap="24" text="lg">
          {intl.formatMessage({id: "modalError.desc"})}
        </WrapperContainer>

        {IS_UFANET_COMPANY && (
          <WrapperContainer gap="24" align="center">
            <img
              src={require("assets/img/mascot/cat-error.svg").default}
              className="img-cat"
              alt=""
            />
          </WrapperContainer>
        )}

        <WrapperContainer align="center">
          <Button isStretch color="primary" size="xxl" onClick={() => window.location.reload()}>
            {intl.formatMessage({id: "btnRefreshPage"})}
          </Button>
        </WrapperContainer>
      </div>
    </div>
  );
};

export default Error;
