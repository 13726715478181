import {requestConfig} from "../config.js";

/**
 * Получить перечень населенных пунктов
 */
export default async function apiFetchCityList() {
  try {
    const response = await requestConfig.get(`api/v2/get_city_list/`);
    return response.data;
  } catch {
    return [];
  }
}
