import React from "react";
import PropTypes from "prop-types";

import Icon from "../Icon/Icon.jsx";

import "./styles/style.scss";

const Spinner = ({color}) => {
  return (
    <div className="spinner">
      <Icon name="spinner" color={color} />
    </div>
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
};

Spinner.defaultProps = {
  color: "currentColor",
};

export default Spinner;
