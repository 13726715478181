import React from "react";
import Button from "../../components/Button/Button.jsx";

import WrapperContainer from "../../components/WrapperContainer/WrapperContainer.jsx";
import {intl} from "../../locale";

import {IS_UFANET_COMPANY} from "../../utils/consts.js";
import {COMPANIES} from "../../vendor.config.js";

const Connected = () => {
  const redirect = COMPANIES[process.env.REACT_APP_VENDOR].urls.accountingPeriodActivationLink;

  return (
    <div className="auth-portal-content">
      <div className="auth-portal-content__title">
        {intl.formatMessage({id: "modalConnected.title"})}
      </div>
      <div className="auth-portal-content__content">
        <WrapperContainer gap="16" text="lg">
          {intl.formatMessage({id: "modalConnected.desc"})}
        </WrapperContainer>

        {IS_UFANET_COMPANY && (
          <WrapperContainer gap="24" align="center">
            <img
              src={require("assets/img/mascot/cat-success.svg").default}
              className="img-cat"
              alt=""
            />
          </WrapperContainer>
        )}

        <Button isStretch color="primary" onClick={() => window.open(redirect)}>
          {intl.formatMessage({id: "modalConnected.btnEnjoy"})}
        </Button>
      </div>
    </div>
  );
};

export default Connected;
