import {COMPANIES} from "../vendor.config.js";

/**
 * Параметр wait для lodash-метода debounce (в мс)
 */
export const DEBOUNCE_DELAY_TIME = 1000;

/**
 * Минимальная и максимальная суммы для оплаты (через Тинькофф)
 */
export const MIN_PAYMENT_SUM_TINKOFF_BANK = 1;
export const MAX_PAYMENT_SUM_TINKOFF_BANK = 15000;

/**
 * Минимальная и максимальная суммы для оплаты (через AMERIABANK)
 */
export const MIN_PAYMENT_SUM_AMERIA_BANK = 1;
export const MAX_PAYMENT_SUM_AMERIA_BANK = 100000;

/**
 * Статусы состояния соединения посетителя портала
 */
export const CONNECTION_STATUSES = Object.freeze({
  AUTHORIZED: 100,
  UNKNOWN_NETWORK: 101,
  UNAUTHORIZED: 102,
  UNPAID: 103,
  FORBIDDEN: 104,
  UNKNOWN: 105,
  // Данный статус используется только в вебе, от сервера он не может быть получены.
  CONNECTED: 106,
});

/**
 * Названия правил для проверки полей формы
 */
export const VALIDATION_RULES = Object.freeze({
  VALID_RANGE_SUM: "isPermissibleSum",
  VALID_FORMAT_EMAIL: "isValidEmail",
  EMPTY_FIELD: "isEmpty",
  SAME_FIELDS: "isSame",
});

/**
 * Название компании, для которой создана сборка
 */
export const COMPANY_NAME = process.env.REACT_APP_VENDOR;

/**
 * Признак, что сборка для Fnet Telecom
 */
export const IS_FNET_COMPANY = COMPANY_NAME.toLowerCase() === COMPANIES.fnet.description.name;

/**
 * Признак, что сборка для Уфанета
 */
export const IS_UFANET_COMPANY = COMPANY_NAME.toLowerCase() === COMPANIES.ufanet.description.name;

/**
 * Доступные эквайринги в РФ
 */
export const PAYMENT_PROVIDER_LIST = Object.freeze({
  SBER: "SBERBANK",
  TINKOFF: "TINKOFF",
});
