import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Select, {components} from "react-select";
import AsyncSelect from "react-select/async";

import Icon from "../Icon/Icon.jsx";
import {noOptionsText, selectStyles} from "./utils/select.js";

import "./styles/style.scss";

const USelect = ({isAsync, size, label, options, placeholder, ...rest}) => {
  /**
   * Вернуть класс, применяемый для размера кнопки
   *
   * @param {String} value
   * @return {String}
   */
  const getSizeClass = value => {
    const classes = {
      xxl: "select--xxl",
      xl: "select--xl",
      lg: "select--lg",
      md: "select--md",
      sm: "select--sm",
      xs: "select--xs",
    };

    return _.get(classes, value, USelect.defaultProps.size);
  };

  const classNames = ["select", getSizeClass(size), rest.className].filter(name => name).join(" ");

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon name="chevron-left" size="lg" color="#AAABAD" />
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = props => {
    const {
      // eslint-disable-next-line react/prop-types
      children = <Icon name="close" size="lg" color="#AAABAD" />,
      // eslint-disable-next-line react/prop-types
      innerProps: {ref, ...restInnerProps},
    } = props;
    return (
      <div {...restInnerProps} ref={ref}>
        {children}
      </div>
    );
  };

  const extraProps = {
    styles: selectStyles,
    noOptionsMessage: noOptionsText,
    placeholder: placeholder,
    className: classNames,
    classNamePrefix: "select",
    components: {ClearIndicator, DropdownIndicator},
  };

  return (
    <div className="input-container">
      {label && <div className="label-field">{label}</div>}

      {!isAsync ? (
        <Select {...rest} {...extraProps} options={options} />
      ) : (
        <AsyncSelect {...rest} {...extraProps} loadOptions={options} />
      )}
    </div>
  );
};

USelect.propTypes = {
  isAsync: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  placeholder: PropTypes.string,
};

USelect.defaultProps = {
  isAsync: false,
  size: "xl",
  label: "",
  options: [],
  placeholder: "",
};

export default USelect;
