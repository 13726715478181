import {requestConfig} from "../config.js";

/**
 * Авторизация в сети Интернет
 *
 * @param {String} login
 * @param {String} password
 */
export default async function apiAuthPortal(login, password) {
  return await requestConfig.post(`api/v2/auth/`, {
    login: login,
    password: password,
  });
}
