import React, {useState} from "react";
import PropTypes from "prop-types";

import AuthFormFields from "./AuthFormFields.jsx";
import Button from "../components/Button/Button.jsx";

import ModalAlert from "../components/Modal/ModalAlert.jsx";
import WrapperContainer from "../components/WrapperContainer/WrapperContainer.jsx";

import BUTTON_NAMES from "../components/Button/utils/consts.js";
import {intl} from "../locale";

const ModalAuthFormCredentials = ({isOpenModal, closeModal}) => {
  const [isFillRequiredFields, setIsFillRequiredFields] = useState(false);

  return (
    <ModalAlert
      isOpenModal={isOpenModal}
      modalTitle={intl.formatMessage({id: "modalAuth.title"})}
      actionModalClose={closeModal}
    >
      <WrapperContainer gap="16" text="lg">
        {intl.formatMessage({id: "modalAuth.desc"})}
      </WrapperContainer>

      <AuthFormFields isFillFields={setIsFillRequiredFields}>
        <div className="buttons-group">
          <Button color="secondary" size="xxl" onClick={closeModal}>
            {intl.formatMessage({id: "btnCancel"})}
          </Button>
          <Button
            isSubmit
            color="primary"
            size="xxl"
            buttonName={BUTTON_NAMES.AUTH}
            disabled={!isFillRequiredFields}
          >
            {intl.formatMessage({id: "modalAuth.btnAuth"})}
          </Button>
        </div>
      </AuthFormFields>
    </ModalAlert>
  );
};

ModalAuthFormCredentials.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalAuthFormCredentials;
