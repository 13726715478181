import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";
import ym from "react-yandex-metrika";

import Button from "../../../components/Button/Button.jsx";
import ModalAddContract from "../../ModalAddContract.jsx";

import apiFetchContract from "../../../api/methods/apiFetchContract.js";
import ModalPayment from "./ModalPayment.jsx";

import WrapperContainer from "../../../components/WrapperContainer/WrapperContainer.jsx";
import Placeholder from "../../../components/Placeholder.jsx";

import {openDocument} from "../../../utils/helpers/openDocument.js";
import AvailableServices from "./AvailableServices.jsx";

import {useErrorHandler} from "../../../utils/hooks/useErrorHandler.js";
import {CONNECTION_STATUSES, IS_UFANET_COMPANY} from "../../../utils/consts.js";

import EXTRA_ACTIONS from "./utils/consts.js";
import {StatusConnectionContext, UpdateInfoContext} from "../../../context";

import {getFormattedCurrency} from "../../../utils/helpers/getFormattedCurrency.js";
import {intl} from "../../../locale";

import "./styles/style.scss";

const Unpaid = () => {
  const {renderError} = useErrorHandler();
  const [favActions, setFavActions] = useState([]);

  const [contractMainInfo, setContractMainInfo] = useState({});
  const [isOpenModalPayment, setIsOpenModalPayment] = useState(false);

  const [isOpenModalAddContract, setIsOpenModalAddContract] = useState(false);
  const setStatusConnection = useContext(StatusConnectionContext);
  const {timeRequestAuth, setIsVisibleAuthButton} = useContext(UpdateInfoContext);

  useEffect(() => {
    (async () => {
      try {
        await getContractInfo();
      } catch (error) {
        renderError(error);
      }
    })();

    setIsVisibleAuthButton(true);
  }, [timeRequestAuth]);

  const getContractInfo = async () => {
    const response = await apiFetchContract();
    const {contract_title, contract_id, billing_id, balance_info, payment_providers} =
      response.detail;

    const availableActionIds = response.detail?.available_actions.map(action => action.type.id);
    let favActions = Object.values(EXTRA_ACTIONS);
    favActions = favActions.filter(action => availableActionIds.includes(action.id));

    setContractMainInfo({
      contract_title,
      contract_id,
      billing_id,
      balance_info: balance_info.recommended,
      payment_providers,
    });
    setFavActions(favActions);
  };

  const openModalPayment = () => {
    if (IS_UFANET_COMPANY) {
      ym("reachGoal", "unpaidPayBtn");
    }
    setIsOpenModalPayment(true);
  };

  const connectAutoPayment = () => {
    if (IS_UFANET_COMPANY) {
      ym("reachGoal", "unpaidAutoPayBtn");
    }
    openDocument("https://my.ufanet.ru/#autopayment");
  };

  if (_.isEmpty(contractMainInfo)) {
    return <Placeholder />;
  }

  /**
   * Возможен кейс, когда денег на договоре достаточно, но учетный период еще не активирован.
   * В таком случае отображаем абоненту экран, что он может пользоваться интернетом
   */
  if (IS_UFANET_COMPANY && contractMainInfo.balance_info === 0) {
    setStatusConnection(CONNECTION_STATUSES.CONNECTED);
  }

  return (
    <>
      <div className="auth-portal-content">
        <div className="auth-portal-content__title">
          {intl.formatMessage({id: "modalUnpaid.title"})}
        </div>
        <div className="auth-portal-content__content">
          <WrapperContainer gap="16" text="lg">
            {intl.formatMessage(
              {id: "modalUnpaid.desc"},
              {contractTitle: <b>{contractMainInfo.contract_title}</b>}
            )}
            <br />
            {intl.formatMessage(
              {id: "modalUnpaid.recSum"},
              {sum: getFormattedCurrency(contractMainInfo.balance_info)}
            )}
          </WrapperContainer>

          {IS_UFANET_COMPANY && (
            <WrapperContainer gap="24" text="lg">
              {intl.formatMessage({id: "modalUnpaid.descExtra"})}
            </WrapperContainer>
          )}

          <WrapperContainer gap={IS_UFANET_COMPANY ? "16" : ""}>
            <Button isStretch color="primary" size="xxl" onClick={openModalPayment}>
              {intl.formatMessage({id: "btnPay"})}
            </Button>
          </WrapperContainer>

          {IS_UFANET_COMPANY && (
            <>
              <WrapperContainer gap="16">
                <Button isStretch color="secondary" size="xxl" onClick={connectAutoPayment}>
                  Подключить автоплатеж
                </Button>
              </WrapperContainer>
              <Button
                isStretch
                color="secondary"
                size="xxl"
                onClick={() => setIsOpenModalAddContract(true)}
              >
                Оформить новый договор
              </Button>
            </>
          )}
        </div>

        {isOpenModalPayment && (
          <ModalPayment
            isOpenModal={isOpenModalPayment}
            contractInfo={contractMainInfo}
            closeModal={() => setIsOpenModalPayment(false)}
          />
        )}

        {isOpenModalAddContract && (
          <ModalAddContract
            isOpenModal={isOpenModalAddContract}
            closeModal={() => setIsOpenModalAddContract(false)}
          />
        )}
      </div>

      {IS_UFANET_COMPANY && !!favActions.length && (
        <AvailableServices services={favActions} updateInfo={getContractInfo} />
      )}
    </>
  );
};

export default Unpaid;
