import React from "react";
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";

import Icon from "../Icon/Icon.jsx";
import Button from "../Button/Button.jsx";

import "./styles/style.scss";

const ALERT_TYPES = Object.freeze({
  SUCCESS: {
    type: "success",
    icon: <Icon name="alert-success" />,
  },
  WARN: {
    type: "warn",
    icon: <Icon name="alert-warn" />,
  },
  ERROR: {
    type: "error",
    icon: <Icon name="alert-error" />,
  },
});

const Alert = ({type, isVisible, actionAlert, titleButton, ...rest}) => {
  if (!isVisible) {
    return null;
  }

  const classNames = ["alert", `alert--${type}`, rest.className].filter(name => name).join(" ");

  return (
    <CSSTransition in={isVisible} timeout={300}>
      <div className={classNames}>
        <div className="alert__icon">{ALERT_TYPES[type.toUpperCase()].icon}</div>
        <div className="alert__message">
          <div className="alert__message-content">{rest.children}</div>
          {actionAlert && (
            <Button size="md" color={type} onClick={actionAlert}>
              {titleButton}
            </Button>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

Alert.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string,
  actionAlert: PropTypes.func,
  titleButton: PropTypes.string,
};

Alert.defaultProps = {
  type: ALERT_TYPES.ERROR.type,
  actionAlert: null,
  titleButton: "",
};

export default Alert;
