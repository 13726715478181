import React, {useState} from "react";
import Button from "../../components/Button/Button.jsx";

import ModalAddContract from "../ModalAddContract.jsx";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer.jsx";

import {intl} from "../../locale";
import {IS_UFANET_COMPANY} from "../../utils/consts.js";

const Unknown = () => {
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

  const openModalConfirm = () => setIsOpenModalConfirm(true);
  const closeModalConfirm = () => setIsOpenModalConfirm(false);

  return (
    <div className="auth-portal-content">
      <div className="auth-portal-content__title">
        {intl.formatMessage({id: "modalUnknown.title"})}
      </div>
      <div className="auth-portal-content__content">
        <WrapperContainer gap="16" text="lg">
          {intl.formatMessage({id: "modalUnknown.desc"})}
        </WrapperContainer>

        {IS_UFANET_COMPANY && (
          <>
            <WrapperContainer gap="24" text="lg">
              {intl.formatMessage({id: "modalUnknown.descExtra"})}
            </WrapperContainer>
            <Button isStretch size="xxl" color="primary" onClick={openModalConfirm}>
              Оформить новый договор
            </Button>
          </>
        )}
      </div>

      {isOpenModalConfirm && (
        <ModalAddContract isOpenModal={isOpenModalConfirm} closeModal={closeModalConfirm} />
      )}
    </div>
  );
};

export default Unknown;
