import React, {useState} from "react";
import PropTypes from "prop-types";

import Placeholder from "../../components/Placeholder.jsx";
import Authorized from "../Screens/Authorized.jsx";

import Unauthorized from "../Screens/Unauthorized.jsx";
import Forbidden from "../Screens/Forbidden.jsx";

import Unpaid from "../Screens/Unpaid/Unpaid.jsx";
import Connected from "../Screens/Connected.jsx";

import Unknown from "../Screens/Unknown.jsx";
import Error from "../Screens/Error.jsx";

import WrapperContainer from "../../components/WrapperContainer/WrapperContainer.jsx";
import Icon from "../../components/Icon/Icon.jsx";

import Button from "../../components/Button/Button.jsx";
import ModalAuthFormCredentials from "../ModalAuthFormCredentials.jsx";

import {intl} from "../../locale";
import {CONNECTION_STATUSES} from "../../utils/consts.js";

import {UpdateInfoContext} from "../../context";
import {COMPANIES} from "../../vendor.config.js";

import "./style.scss";

const AuthContainer = ({statusConnection}) => {
  const [timeRequestAuth, setTimeRequestAuth] = useState(null);

  const [isVisibleAuthButton, setIsVisibleAuthButton] = useState(false);
  const [isOpenModalAuthForm, setIsOpenModalAuthForm] = useState(false);

  return (
    <div className="auth-portal-container">
      {isVisibleAuthButton && (
        <Button
          paddingOff="all"
          icon="right"
          className="button--login"
          onClick={() => setIsOpenModalAuthForm(true)}
        >
          <div>{intl.formatMessage({id: "btnAnotherContract"})}</div>
          <Icon name="login" />
        </Button>
      )}

      <WrapperContainer gap="24" align="center">
        <img
          src={COMPANIES[process.env.REACT_APP_VENDOR].imgs.logoPrimary}
          className="logo-company"
          alt=""
        />
      </WrapperContainer>

      {statusConnection ? (
        <UpdateInfoContext.Provider
          value={{
            timeRequestAuth,
            setTimeRequestAuth,
            setIsVisibleAuthButton,
            setIsOpenModalAuthForm,
          }}
        >
          {statusConnection === CONNECTION_STATUSES.AUTHORIZED && <Authorized />}
          {statusConnection === CONNECTION_STATUSES.UNKNOWN_NETWORK && <Unknown />}
          {statusConnection === CONNECTION_STATUSES.UNAUTHORIZED && <Unauthorized />}
          {statusConnection === CONNECTION_STATUSES.UNPAID && <Unpaid />}
          {statusConnection === CONNECTION_STATUSES.FORBIDDEN && <Forbidden />}
          {statusConnection === CONNECTION_STATUSES.CONNECTED && <Connected />}
          {statusConnection === CONNECTION_STATUSES.UNKNOWN && <Error />}

          {isOpenModalAuthForm && (
            <ModalAuthFormCredentials
              isOpenModal={isOpenModalAuthForm}
              closeModal={() => setIsOpenModalAuthForm(false)}
            />
          )}
        </UpdateInfoContext.Provider>
      ) : (
        <Placeholder />
      )}
    </div>
  );
};

AuthContainer.propTypes = {
  statusConnection: PropTypes.number,
};

AuthContainer.defaultProps = {
  statusConnection: null,
};

export default AuthContainer;
