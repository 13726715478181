import React, {useContext} from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Spinner from "../Spinner/Spinner.jsx";
import {LoadingContext} from "../../context";

import "./styles/style.scss";

const Button = ({
  isSubmit,
  isStretch,
  color,
  spinnerColor,
  size,
  paddingOff,
  icon,
  buttonName,
  ...rest
}) => {
  const {spinnerTarget} = useContext(LoadingContext);

  /**
   * Вернуть класс, применяемый для установки цвета кнопки
   *
   * @param {String} value
   * @return {String}
   */
  const getColorClass = value => {
    const classes = {
      primary: "button--primary",
      secondary: "button--secondary",
      success: "button--notify-success",
      warn: "button--notify-warn",
      error: "button--notify-error",
    };

    return _.get(classes, value, Button.defaultProps.color);
  };

  /**
   * Вернуть класс, применяемый для установки размера кнопки
   *
   * @param {String} value
   * @return {String}
   */
  const getSizeClass = value => {
    const classes = {
      xxl: "button--xxl",
      xl: "button--xl",
      lg: "button--lg",
      md: "button--md",
      sm: "button--sm",
      xs: "button--xs",
    };

    return _.get(classes, value, Button.defaultProps.size);
  };

  /**
   * Вернуть класс, применяемый для отображения кнопки с иконкой
   *
   * @param {String} value
   * @return {String}
   */
  const getIconClass = value => {
    const iconClasses = {
      only: "button--icon",
      left: "button--icon-left",
      right: "button--icon-right",
    };

    return _.get(iconClasses, value, Button.defaultProps.icon);
  };

  /**
   * Вернуть класс, применяемый для удаления внутреннего отступа с определенной стороны
   *
   * @param {String} value
   * @return {String}
   */
  const getPaddingClass = value => {
    const classes = {
      all: "button--padding0",
      lr: "button--padding-lr0",
      tp: "button--padding-tb0",
    };

    return _.get(classes, value, Button.defaultProps.padding);
  };

  const classNames = `${[
    "button",
    isStretch && "button--stretch",
    getColorClass(color),
    getSizeClass(size),
    getPaddingClass(paddingOff),
    getIconClass(icon),
    rest.className,
  ]
    .filter(btnClass => btnClass)
    .join(" ")}`;

  return (
    <button {...rest} type={isSubmit ? "submit" : "button"} className={classNames}>
      {buttonName && (
        <>
          <div className="button-title" style={{opacity: +(spinnerTarget !== buttonName)}}>
            {rest.children}
          </div>
          {spinnerTarget === buttonName && (
            <div className="button-spinner">
              <Spinner color={spinnerColor} />
            </div>
          )}
        </>
      )}
      {!buttonName && rest.children}
    </button>
  );
};

Button.propTypes = {
  isSubmit: PropTypes.bool,
  isStretch: PropTypes.bool,
  color: PropTypes.string,
  spinnerColor: PropTypes.string,
  size: PropTypes.string,
  paddingOff: PropTypes.string,
  icon: PropTypes.string,
  buttonName: PropTypes.string,
};

Button.defaultProps = {
  isSubmit: false,
  isStretch: false,
  color: "",
  spinnerColor: "currentColor",
  size: "xl",
  paddingOff: "",
  icon: "",
  buttonName: "",
};

export default Button;
