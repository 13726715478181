import React, {useContext, useEffect, useState} from "react";
import Placeholder from "../../components/Placeholder.jsx";

import WrapperContainer from "../../components/WrapperContainer/WrapperContainer.jsx";
import apiFetchContract from "../../api/methods/apiFetchContract.js";

import {useErrorHandler} from "../../utils/hooks/useErrorHandler.js";
import {intl} from "../../locale";
import {UpdateInfoContext} from "../../context";

const Forbidden = () => {
  const {renderError} = useErrorHandler();

  const [contractTitle, setContractTitle] = useState("");
  const {setIsVisibleAuthButton} = useContext(UpdateInfoContext);

  useEffect(() => {
    (async () => {
      try {
        const response = await apiFetchContract();
        setContractTitle(response.detail.contract_title);
      } catch (error) {
        renderError(error);
      }
    })();

    setIsVisibleAuthButton(true);
  }, []);

  if (!contractTitle) {
    return <Placeholder />;
  }

  return (
    <div className="auth-portal-content">
      <div className="auth-portal-content__title">
        {intl.formatMessage({id: "modalForbidden.title"})}
      </div>
      <div className="auth-portal-content__content">
        <WrapperContainer text="lg">
          {intl.formatMessage({id: "modalForbidden.desc"}, {contractTitle: <b>{contractTitle}</b>})}
        </WrapperContainer>
      </div>
    </div>
  );
};

export default Forbidden;
