import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const WrapperContainer = ({title, text, gap, align, ...rest}) => {
  /**
   * Если оборачиваемые данные title
   *
   * @param {String} value
   * @return {String}
   */
  const getTitleClass = value => {
    const classes = {
      xxl: "title-xxl ",
      xl: "title-xl",
      lg: "title-lg",
      md: "title-md",
      sm: "title-sm",
    };

    return _.get(classes, value, WrapperContainer.defaultProps.title);
  };

  /**
   * Если оборачиваемые данные text
   *
   * @param {String} value
   * @return {String}
   */
  const getTextClass = value => {
    const classes = {
      xxl: "text-xxl",
      xl: "text-xl",
      lg: "text-lg",
      md: "text-md",
      sm: "text-sm",
    };

    return _.get(classes, value, WrapperContainer.defaultProps.text);
  };

  /**
   * Вернуть класс, применяемый для отступа снизу
   *
   * @param {String} value
   * @return {String}
   */
  const getGapClass = value => {
    const classes = {
      16: "text-vgap16",
      24: "text-vgap24",
    };

    return _.get(classes, value, WrapperContainer.defaultProps.gap);
  };

  /**
   * Вернуть класс, применяемый для позиционирования по горизонтали
   *
   * @param {String} value
   * @return {String}
   */
  const getAlignClass = value => {
    const classes = {
      left: "text-left",
      right: "text-right",
      center: "text-center",
    };

    return _.get(classes, value, WrapperContainer.defaultProps.align);
  };

  const classNames = `${[
    getTitleClass(title),
    getTextClass(text),
    getGapClass(gap),
    getAlignClass(align),
    rest.className,
  ]
    .filter(btnClass => btnClass)
    .join(" ")}`;

  return <div className={classNames}>{rest.children}</div>;
};

WrapperContainer.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  gap: PropTypes.string,
  align: PropTypes.string,
};

WrapperContainer.defaultProps = {
  title: "",
  text: "",
  gap: "",
  align: "",
};

export default WrapperContainer;
