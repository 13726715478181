import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";

import QRCode from "qrcode-svg";
import ModalAlert from "../components/Modal/ModalAlert";

import WrapperContainer from "components/WrapperContainer/WrapperContainer";
import Icon from "components/Icon/Icon";

import apiFetchIp from "api/methods/apiFetchIp";
import apiFetchStatusConnection from "api/methods/apiFetchStatusConnection";

import Button from "components/Button/Button";
import {StatusConnectionContext} from "../context";

import {CONNECTION_STATUSES} from "../utils/consts";
export const SVG_GENERATION_SETTINGS = {
  join: true,
  padding: 5,
  width: 214,
  height: 214,
  color: "#000",
  background: "#fff",
};

const ModalAuthFormByQrCode = ({isOpenModal, closeModal}) => {
  const [qrcode, setQrcode] = useState(null);
  const [isFetchData, setIsFetchData] = useState(true);

  const [isOverTime, setIsOverTime] = useState(false);
  const setStatusConnection = useContext(StatusConnectionContext);

  let intervalId = null;

  useEffect(() => {
    getDataAndCreateQrCode();
    return () => clearInterval(intervalId);
  }, []);

  /**
   * Получить данные и в случае успеха создать qr-код
   */
  const getDataAndCreateQrCode = async () => {
    try {
      const response = await apiFetchIp();
      if (!response?.detail) throw new Error();

      generateQrCode(response.detail.ip_client);

      intervalId = createTimer();
      setTimeout(() => {
        clearInterval(intervalId);
        setIsOverTime(true);
      }, 30000);
    } catch {
      setQrcode(null);
    } finally {
      setIsFetchData(false);
    }
  };

  /**
   * Создать qr-код
   *
   * @param {String} data
   */
  const generateQrCode = data => {
    const code = new QRCode({
      content: JSON.stringify(data),
      ...SVG_GENERATION_SETTINGS,
    }).svg();
    setQrcode(code);
  };

  /**
   * Создать таймер (для слежения за статусом соединения)
   */
  const createTimer = () => {
    return setInterval(async () => {
      const response = await apiFetchIp();

      if (response.status_id === 511) {
        const statusConnection = await apiFetchStatusConnection();

        if (statusConnection.status_id === CONNECTION_STATUSES.AUTHORIZED) {
          setStatusConnection(CONNECTION_STATUSES.CONNECTED);
        } else {
          setStatusConnection(statusConnection.status_id);
        }
        closeModal();
      }

      if (!response?.detail && response.status_id !== 511)
        throw new Error("Не удалось сформировать qr-код");
    }, 5000);
  };

  /**
   * Обновить данные
   */
  const updateData = () => {
    setIsFetchData(true);
    setIsOverTime(false);
    getDataAndCreateQrCode();
  };

  return (
    <ModalAlert isOpenModal={isOpenModal} modalTitle="" actionModalClose={closeModal}>
      <WrapperContainer align="center">
        <WrapperContainer gap="24">
          <div className="wrapper-qr-code">
            <div
              className="qr-code"
              dangerouslySetInnerHTML={{__html: qrcode}}
              style={{opacity: isOverTime ? ".6" : "1", border: !qrcode && "none"}}
            ></div>
            {isOverTime && (
              <Button className="refresh-button" onClick={updateData}>
                <Icon name="refresh" color="#FF7733" />
              </Button>
            )}
            {!qrcode && !isFetchData && (
              <div className="qr-code-error">
                <WrapperContainer text="sm">
                  Не удалось загрузить QR-код.
                  <br /> Повторите позже
                </WrapperContainer>
              </div>
            )}
          </div>
        </WrapperContainer>

        <WrapperContainer title="sm" gap="16">
          Вход по QR - коду
        </WrapperContainer>
        <WrapperContainer text="lg" gap="16">
          Отсканируйте QR-код через приложение для авторизации
        </WrapperContainer>
        <WrapperContainer text="md">
          <div style={{color: "#AAABAD"}}>Доступно только для сотрудников</div>
        </WrapperContainer>
      </WrapperContainer>
    </ModalAlert>
  );
};

ModalAuthFormByQrCode.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalAuthFormByQrCode;
