import {createContext} from "react";

/**
 * Контекст для установки ошибки при работе с API
 */
export const ApiErrorContext = createContext(null);

/**
 * Контекст для возможности изменять состояние соединения
 */
export const StatusConnectionContext = createContext(null);

/**
 * Контекст для управления глобальным оверлеем.
 * Нужен для того, чтобы блокировать возможность взаимодействовать
 * пользователю с UI, на время пока осуществляется запрос на сервер.
 * Также на состояние контекста завязано отображение спинера внутри кнопок
 */
export const LoadingContext = createContext(null);

/**
 * Контекст, позволяющий в любом компоненте из директории проекта views обновить
 * некоторую информацию после запроса на авторизацию
 */
export const UpdateInfoContext = createContext(null);
