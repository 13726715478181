import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import "./styles/style.scss";

const Checkbox = ({size, ...rest}) => {
  /**
   * Вернуть класс, применяемый для размера компонента
   *
   * @param {String} value
   * @return {String}
   */
  const getSizeClass = value => {
    const classes = {
      lg: "checkbox--lg",
      md: "checkbox--md",
      sm: "checkbox--sm",
    };

    return _.get(classes, value, Checkbox.defaultProps.size);
  };

  const {children, ...domProps} = rest;
  const classNames = `${[
    "checkbox",
    getSizeClass(size),
    rest.disabled && "checkbox--disabled",
    rest.className,
  ]
    .filter(btnClass => btnClass)
    .join(" ")}`;

  return (
    <label className={classNames}>
      <input {...domProps} type="checkbox" />
      <span className="checkmark"></span>
      {children}
    </label>
  );
};

Checkbox.propTypes = {
  size: PropTypes.string,
};

Checkbox.defaultProps = {
  size: "lg",
};

export default Checkbox;
