import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import Button from "../Button/Button.jsx";
import Icon from "../Icon/Icon.jsx";

import "./styles/style.scss";

const ModalAlert = ({isOpenModal, modalTitle, actionModalClose, ...rest}) => {
  return (
    <Modal
      isOpen={isOpenModal}
      ariaHideApp={false}
      onRequestClose={actionModalClose}
      parentSelector={() => document.getElementById("app")}
      bodyOpenClassName={null}
      portalClassName="modal-portal"
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <div className="modal-content__header">
        <div className="modal-content__header-title">{modalTitle}</div>
        <Button icon="only" paddingOff="all" onClick={actionModalClose}>
          <Icon name="close" />
        </Button>
      </div>
      <div className="modal-content__content">{rest.children}</div>
    </Modal>
  );
};

ModalAlert.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  actionModalClose: PropTypes.func.isRequired,
};

export default ModalAlert;
