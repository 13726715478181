import {
  MAX_PAYMENT_SUM_TINKOFF_BANK,
  MIN_PAYMENT_SUM_TINKOFF_BANK,
  VALIDATION_RULES,
} from "../consts.js";

/**
 * Для использования функции проверки обязательно требуется указание
 * ref для каждого поля ввода и указание состояния валидности для проверяемого поля
 */
export default function validateInputFields() {
  /**
   * Функция, принимающая перечень правил VALIDATION_RULES.
   * Можно устанавливать несколько правил на одном поле ввода.
   * У правила есть обязательное условие валидности проверяемого значения
   *
   * @param {Array<Object>} rules
   * @return {Object}
   */
  const validate = rules => {
    // Перечень ошибок в ходе проверки
    const errors = [];

    // Результат проверки конкретного поля ввода
    let validationResults = {isValid: true, errors: errors};

    rules.forEach(condition => {
      /**
       * Проверка поля на пустоту
       */
      if (VALIDATION_RULES.EMPTY_FIELD === condition.type) {
        const isValid = condition.targets[0].current?.value.length;

        if (!isValid) {
          errors.push(condition.error);
          validationResults = {
            isValid: false,
            errors: errors,
          };
          validateError(condition);
        } else {
          validateSuccess(condition);
        }
      }

      /**
       * Проверка на равенство значений в двух полях ввода
       */
      if (VALIDATION_RULES.SAME_FIELDS === condition.type) {
        if (!condition.targets[1].current.value.length) {
          return false;
        }

        if (condition.targets[0].current.value !== condition.targets[1].current.value) {
          errors.push(condition.error);
          validationResults = {
            isValid: false,
            errors: errors,
          };
          validateError(condition);
        } else {
          validateSuccess(condition);
        }
      }

      /**
       * Проверка поля суммы на min/max
       */
      if (VALIDATION_RULES.VALID_RANGE_SUM === condition.type) {
        const sum = condition.targets[0].current?.value;

        // Ничего не делаем пока элемент не будет доступен в DOM
        if (typeof sum === "undefined") {
          return false;
        }

        const isValidSumPayment =
          sum >= MIN_PAYMENT_SUM_TINKOFF_BANK && MAX_PAYMENT_SUM_TINKOFF_BANK >= sum;

        if (!isValidSumPayment) {
          errors.push(condition.error);
          validationResults = {
            isValid: false,
            errors: errors,
          };
          validateError(condition);
        } else {
          validateSuccess(condition);
        }
      }

      /**
       * Проверка формата электронной почты
       */
      if (VALIDATION_RULES.VALID_FORMAT_EMAIL === condition.type) {
        const REG_EXP_EMAIL =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
        const isValidEmail = REG_EXP_EMAIL.test(condition.targets[0].current.value);

        if (!isValidEmail) {
          errors.push(condition.error);
          validationResults = {
            isValid: false,
            errors: errors,
          };
          validateError(condition);
        } else {
          validateSuccess(condition);
        }
      }
    });

    return validationResults;
  };

  /**
   * Функция для манипуляций с DOM если значение валидное
   *
   * @param {Object} condition
   */
  const validateSuccess = condition => {
    condition.targets.forEach(target => target.current.classList.remove("input--invalid"));
  };

  /**
   * Функция для манипуляций с DOM если значение невалидное
   *
   * @param {Object} condition
   */
  const validateError = condition => {
    condition.targets.forEach(target => target.current.classList.add("input--invalid"));
  };

  return {
    validate,
  };
}
