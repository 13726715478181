import React from "react";
import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";

const Placeholder = ({count}) => {
  return (
    <div className="placeholder-container">
      {Array.from(Array(count)).map((item, index) => (
        <ContentLoader
          key={index}
          speed={2}
          width="100%"
          viewBox="0 0 340 84"
          backgroundColor="#d6d6d6"
          foregroundColor="#ededed"
        >
          <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
          <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
          <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
          <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
          <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
          <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
          <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
          <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
        </ContentLoader>
      ))}
    </div>
  );
};

Placeholder.propTypes = {
  count: PropTypes.number,
};

Placeholder.defaultProps = {
  count: 2,
};

export default Placeholder;
