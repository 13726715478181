import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import "./styles/style.scss";

// eslint-disable-next-line react/display-name
const Input = React.forwardRef(({isStretch, label, type, size, validationErrors, ...rest}, ref) => {
  /**
   * Вернуть класс, применяемый для размера поля ввода
   *
   * @param {String} value
   * @return {String}
   */
  const getSizeClass = value => {
    const classes = {
      xxl: "input--xxl",
      xl: "input--xl",
      lg: "input--lg",
      md: "input--md",
      sm: "input--sm",
      xs: "input--xs",
    };

    return _.get(classes, value, Input.defaultProps.size);
  };

  const {children, ...domProps} = rest;
  const classNames = `${["input", isStretch && "input--stretch", getSizeClass(size), rest.className]
    .filter(btnClass => btnClass)
    .join(" ")}`;

  return (
    <div className="input-container">
      {label && <div className="label-field">{label}</div>}

      <div className="input-wrapper">
        <input {...domProps} type={type} className={classNames} ref={ref} />
        {children}
      </div>

      {!!validationErrors.length && (
        <div className="input-errors">
          {validationErrors.map((error, index) => (
            <div key={index} className="input-errors__error">
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

Input.propTypes = {
  isStretch: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  validationErrors: PropTypes.array,
};

Input.defaultProps = {
  isStretch: false,
  label: "",
  type: "text",
  size: "xl",
  validationErrors: [],
};

export default Input;
