/**
 * Глобальные свойства для отдельных порталов компании
 */
export const COMPANIES = Object.freeze({
  ufanet: {
    description: {
      name: "ufanet",
      label: "Уфанет",
      labelFull: "АО Уфанет",
    },
    support: {
      phone: "09388",
      aliasCity: "ufa",
    },
    urls: {
      mobileConnectionPage: "https://my.ufanet.ru/iwantufanet",
      accountingPeriodActivationLink: "https://www.ufanet.ru/",
    },
    imgs: {
      logoPrimary: require("assets/img/logo/logo-ufanet-primary.svg").default,
    },
    theme: "unet",
    yaCounter: 90139378,
  },
  fnet: {
    description: {
      name: "fnet",
      label: "Fnet Telecom",
      labelFull: "Fnet Telecom",
    },
    support: {
      phone: "+374 11 571616",
      aliasCity: null,
    },
    urls: {
      mobileConnectionPage: null,
      accountingPeriodActivationLink: "https://www.google.com/",
    },
    imgs: {
      logoPrimary: require("assets/img/logo/logo-fnet-primary.svg").default,
    },
    theme: "fnet",
    yaCounter: null,
  },
});
