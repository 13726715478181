import React from "react";
import PropTypes from "prop-types";

import ModalAlert from "../components/Modal/ModalAlert.jsx";
import WrapperContainer from "../components/WrapperContainer/WrapperContainer.jsx";

import Button from "../components/Button/Button.jsx";
import {COMPANIES} from "../vendor.config.js";

const ModalAddContract = ({isOpenModal, closeModal}) => {
  const openPage = () =>
    window.open(COMPANIES[process.env.REACT_APP_VENDOR].urls.mobileConnectionPage);

  return (
    <ModalAlert
      isOpenModal={isOpenModal}
      modalTitle="Оформить новый договор"
      actionModalClose={closeModal}
    >
      <WrapperContainer gap="16" text="lg">
        Для заключения нового договора нужно будет авторизоваться через Госуслуги и внести оплату за
        первый месяц по выбранному тарифу.
      </WrapperContainer>

      <WrapperContainer gap="24" text="lg">
        Если дома есть наш кабель, Интернетом можно будет начать пользоваться сразу. Если кабеля
        нет, то потребуется выезд Инженера.
      </WrapperContainer>

      <div className="buttons-group">
        <Button color="secondary" size="xxl" onClick={closeModal}>
          Отменить
        </Button>
        <Button color="primary" size="xxl" onClick={openPage}>
          Продолжить
        </Button>
      </div>
    </ModalAlert>
  );
};

ModalAddContract.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalAddContract;
