import {requestConfig} from "../../config.js";

/**
 * Получить ссылку на страницу оплаты Сбера
 *
 * @param {Number} contractId
 * @param {String} billingId
 * @param {Number} sumPayment
 */
export default async function apiFetchSberPaymentUrl(contractId, billingId, sumPayment) {
  const response = await requestConfig.post("api/v0/pay/pay", {
    contract_id: contractId,
    billing_id: billingId,
    sum: sumPayment,
  });

  return response.data;
}
