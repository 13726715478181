import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import sprite from "../../assets/img/sprite.svg";
import "./styles/style.scss";

// eslint-disable-next-line react/display-name
const Icon = React.forwardRef(({isAccent, size, name, color, ...rest}, ref) => {
  /**
   * Вернуть класс, применяемый для размера иконки
   *
   * @param {String} value
   * @return {String}
   */
  const getSizeClass = value => {
    const classes = {
      xl: "icon--xl",
      lg: "icon--lg",
      md: "icon--md",
      sm: "icon--sm",
    };

    return _.get(classes, value, Icon.defaultProps.size);
  };

  const classNames = ["icon", getSizeClass(size), isAccent && "icon--accent", rest.className]
    .filter(name => name)
    .join(" ");

  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      {...rest}
      className={classNames}
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
    >
      <use xlinkHref={`${sprite}#${name}`} fill={color} />
    </svg>
  );
});

Icon.propTypes = {
  isAccent: PropTypes.bool,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  ref: PropTypes.object,
};

Icon.defaultProps = {
  isAccent: false,
  size: "xl",
  color: "currentColor",
  ref: null,
};

export default Icon;
