import {requestConfig} from "../../config.js";

/**
 * Получить настройки для оплаты
 *
 * @param {Number} contractId
 */
export default async function apiFetchAmeriaPaymentInit(contractId) {
  try {
    const response = await requestConfig.get("ameriabank/public/api/v0/processing/policy", {
      params: {
        contractId: contractId,
      },
    });

    return response.data[0];
  } catch (error) {
    throw error;
  }
}
