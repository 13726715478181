import React, {useEffect, useState, useContext} from "react";
import PropTypes from "prop-types";

import Input from "../components/Input/Input.jsx";
import Button from "../components/Button/Button.jsx";

import Alert from "../components/Alert/Alert.jsx";
import WrapperContainer from "../components/WrapperContainer/WrapperContainer.jsx";

import Icon from "../components/Icon/Icon.jsx";
import {StatusConnectionContext, LoadingContext, UpdateInfoContext} from "../context";

import {CONNECTION_STATUSES, IS_UFANET_COMPANY} from "../utils/consts.js";
import apiAuthPortal from "../api/methods/apiAuthPortal.js";

import {renderNotify} from "../components/NotifyMessage/utils/renderNotify.js";
import apiFetchStatusConnection from "../api/methods/apiFetchStatusConnection.js";

import BUTTON_NAMES from "../components/Button/utils/consts.js";
import {useErrorHandler} from "../utils/hooks/useErrorHandler.js";

import ModalAuthFormByQrCode from "features/ModalAuthFormByQrCode.jsx";
import {intl} from "../locale";

const AuthFormFields = ({isFillFields, ...rest}) => {
  const {renderError} = useErrorHandler();
  const [login, setLogin] = useState("");

  const [password, setPassword] = useState("");
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const [errorText, setErrorText] = useState("");
  const setStatusConnection = useContext(StatusConnectionContext);

  const {setSpinnerTarget} = useContext(LoadingContext);
  const {setTimeRequestAuth, setIsOpenModalAuthForm} = useContext(UpdateInfoContext);

  const [isOpenModalAuthByQrCode, setIsOpenModalAuthByQrCode] = useState(false);

  useEffect(() => {
    isFillFields(Boolean(login && password));
  }, [login, password]);

  /**
   * Авторизовать посетителя в сети Уфанет
   *
   * @param {Object} event
   */
  const authOnPortal = async event => {
    try {
      event.preventDefault();
      setSpinnerTarget(BUTTON_NAMES.AUTH);

      const response = await apiAuthPortal(login, password);
      if (response?.data?.status === "error") {
        setErrorText(response.data?.error_message);
      } else {
        renderNotify(
          "success",
          null,
          intl.formatMessage({id: "apiSuccess.authPortal"}, {contractTitle: login})
        );

        /**
         * Если удалось авторизовать, то сразу после нужно переполучить статус соединения
         * для того, чтобы отобразить нужный экран.
         *
         * !!! На роутере новая сессия может подниматься не всегда мгновенно,
         * то есть может быть некоторая задержка.
         * Такое поведение было замечено только при переавторизации.
         *
         * Чревато тем, что будет возвращаться некорректный статус при запросе /ip/info/
         * сразу после выполнения запроса авторизации /auth/
         */
        let statusConnection = null;

        // eslint-disable-next-line no-undef
        const timeout = new Promise(resolve =>
          setTimeout(() => resolve(apiFetchStatusConnection()), 3000)
        );

        statusConnection = await timeout;
        setIsOpenModalAuthForm(false);

        if (statusConnection?.status_id === CONNECTION_STATUSES.AUTHORIZED) {
          setStatusConnection(CONNECTION_STATUSES.CONNECTED);
        } else {
          setStatusConnection(statusConnection?.status_id);
          setTimeRequestAuth(+new Date());
        }
      }
    } catch (error) {
      renderError(error);
    } finally {
      setSpinnerTarget(null);
    }
  };

  /**
   * Показать/скрыть введенный пароль
   */
  const changeVisiblePassword = () => {
    const input = document.getElementById("authFieldPassword");

    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }
    setIsVisiblePassword(prevState => !prevState);
  };

  return (
    <form onSubmit={authOnPortal}>
      <WrapperContainer gap="16">
        <Alert isVisible={!!errorText}>{errorText}</Alert>
      </WrapperContainer>

      <WrapperContainer gap="16">
        <Input
          isStretch
          placeholder={intl.formatMessage({id: "placeholderContractNumber"})}
          onChange={event => setLogin(event.target.value)}
          autoComplete="new-contract"
        />
      </WrapperContainer>

      <WrapperContainer gap="24">
        <Input
          isStretch
          type="password"
          placeholder={intl.formatMessage({id: "placeholderContractPassword"})}
          onChange={event => setPassword(event.target.value)}
          autoComplete="new-password"
          id="authFieldPassword"
        >
          <Button icon="only" paddingOff="all" onClick={changeVisiblePassword}>
            {isVisiblePassword ? (
              <Icon name="eye" color="#AAABAD" />
            ) : (
              <Icon name="eye-off" color="#AAABAD" />
            )}
          </Button>
        </Input>
      </WrapperContainer>
      {IS_UFANET_COMPANY && (
        <WrapperContainer gap="24">
          <div className="utility-service" onClick={() => setIsOpenModalAuthByQrCode(true)}>
            <div className="utility-service__text">Быстрый вход по QR (для сотрудников)</div>
            <Icon name="qr-code" />
          </div>
        </WrapperContainer>
      )}
      {rest.children}

      {isOpenModalAuthByQrCode && (
        <ModalAuthFormByQrCode
          isOpenModal={isOpenModalAuthByQrCode}
          closeModal={() => setIsOpenModalAuthByQrCode(false)}
        />
      )}
    </form>
  );
};

AuthFormFields.propTypes = {
  isFillFields: PropTypes.func.isRequired,
};

export default AuthFormFields;
