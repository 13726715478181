import React from "react";
import Icon from "../../../../components/Icon/Icon.jsx";

const EXTRA_ACTIONS = {
  CREDIT: {
    id: 1,
    title: "Кредит доверия",
    description: "Продлить услугу на 5 дней",
    icon: <Icon name="clock" />,
  },
  AVAILABLE_INTERNET: {
    id: 128,
    title: "Доступный Интернет",
    description: "Интернет для социально значимых сайтов",
    icon: <Icon name="favorite" />,
  },
};

export default EXTRA_ACTIONS;
