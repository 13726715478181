import {locales} from "../../locale";

/**
 * Преобразовать сумму в русскую локаль
 *
 * @param {Number} currencyValue
 * @return {String}
 */
export const getFormattedCurrency = currencyValue => {
  return new Intl.NumberFormat(locales[process.env.REACT_APP_LOCALE].name, {
    style: "currency",
    currency: locales[process.env.REACT_APP_LOCALE].currency,
  }).format(currencyValue);
};
