import {requestConfig} from "../../config.js";

/**
 * Получить ссылку на страницу оплаты Тинькофф Банка
 *
 * @param {Number} contractId
 * @param {String} billingId
 * @param {Number} sumPayment
 * @param {String} email
 */
export default async function apiFetchTinkoffPaymentUrl(
  contractId,
  billingId,
  sumPayment,
  email = null
) {
  const redirectUrl = window.location.href;

  const response = await requestConfig.post("api/v2/tinkoff/payments/url", {
    cid: contractId,
    bid: billingId,
    amount: sumPayment,
    platform: "WEB",
    method: "CARD",
    customer: 0,
    success_url: `${redirectUrl}?isPaid=true`,
    fail_url: `${redirectUrl}?isPaid=false`,
    email: email,
  });

  return response.data;
}
