import {requestConfig} from "../config.js";

/**
 * Получить ip посетителя портала
 */
export default async function apiFetchIp() {
  try {
    const response = await requestConfig.get("api/v0/qrcode/get_ip_qrcode/");
    return response.data;
  } catch (error) {
    if (error.response.status === 403) {
      return {
        status_id: 511,
      };
    }
  }
}
