import React, {useState} from "react";
import Button from "../../components/Button/Button.jsx";

import ModalAddContract from "../ModalAddContract.jsx";
import AuthFormFields from "../AuthFormFields.jsx";

import WrapperContainer from "../../components/WrapperContainer/WrapperContainer.jsx";
import BUTTON_NAMES from "../../components/Button/utils/consts.js";

import {IS_UFANET_COMPANY} from "../../utils/consts.js";
import {intl} from "../../locale";

import "./Unpaid/styles/style.scss";

const Unauthorized = () => {
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false),
    [isFillRequiredFields, setIsFillRequiredFields] = useState(false);

  return (
    <>
      <div className="auth-portal-content">
        <div className="auth-portal-content__title">
          {intl.formatMessage({id: "modalUnauthorized.title"})}
        </div>
        <div className="auth-portal-content__content">
          <WrapperContainer gap="16" text="lg">
            {intl.formatMessage({id: "modalUnauthorized.desc"})}
          </WrapperContainer>

          <AuthFormFields isFillFields={setIsFillRequiredFields}>
            <WrapperContainer gap={IS_UFANET_COMPANY ? "16" : ""}>
              <Button
                isSubmit
                isStretch
                color="primary"
                size="xxl"
                buttonName={BUTTON_NAMES.AUTH}
                disabled={!isFillRequiredFields}
              >
                {intl.formatMessage({id: "btnOnwards"})}
              </Button>
            </WrapperContainer>
          </AuthFormFields>

          {IS_UFANET_COMPANY && (
            <Button
              isStretch
              color="secondary"
              size="xxl"
              onClick={() => setIsOpenModalConfirm(true)}
            >
              Оформить новый договор
            </Button>
          )}
        </div>

        {isOpenModalConfirm && (
          <ModalAddContract
            isOpenModal={isOpenModalConfirm}
            closeModal={() => setIsOpenModalConfirm(false)}
          />
        )}
      </div>
    </>
  );
};

export default Unauthorized;
