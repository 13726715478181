import React, {useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Checkbox from "../../../components/Checkbox/Checkbox.jsx";
import WrapperContainer from "../../../components/WrapperContainer/WrapperContainer.jsx";
import Input from "../../../components/Input/Input.jsx";

import Button from "../../../components/Button/Button.jsx";
import ModalAlert from "../../../components/Modal/ModalAlert.jsx";

import {
  DEBOUNCE_DELAY_TIME,
  IS_FNET_COMPANY,
  IS_UFANET_COMPANY,
  MAX_PAYMENT_SUM_AMERIA_BANK,
  MAX_PAYMENT_SUM_TINKOFF_BANK,
  MIN_PAYMENT_SUM_AMERIA_BANK,
  MIN_PAYMENT_SUM_TINKOFF_BANK,
  PAYMENT_PROVIDER_LIST,
  VALIDATION_RULES,
} from "../../../utils/consts.js";

import validateInputFields from "../../../utils/helpers/validateInputFields.js";
import {useErrorHandler} from "../../../utils/hooks/useErrorHandler.js";

import apiFetchAmeriaPaymentUrl from "../../../api/methods/payment/apiFetchAmeriaPaymentUrl.js";
import apiFetchAmeriaPaymentInit from "../../../api/methods/payment/apiFetchAmeriaPaymentInit.js";

import apiFetchTinkoffPaymentUrl from "../../../api/methods/payment/apiFetchTinkoffPaymentUrl.js";
import {getFormattedCurrency} from "../../../utils/helpers/getFormattedCurrency.js";

import {intl, locale, locales} from "../../../locale";
import apiFetchSberPaymentUrl from "../../../api/methods/payment/apiFetchSberPaymentUrl.js";

import BUTTON_NAMES from "../../../components/Button/utils/consts.js";
import {LoadingContext} from "../../../context";

const ModalPayment = ({isOpenModal, contractInfo, closeModal}) => {
  const {setSpinnerTarget} = useContext(LoadingContext);
  const [sumPayment, setSumPayment] = useState(
    contractInfo.balance_info || MIN_PAYMENT_SUM_TINKOFF_BANK
  );

  const [minAmount, setMinAmount] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);

  const [currency, setCurrency] = useState("");
  const [email, setEmail] = useState("");

  // Результат валидации для поля ввода суммы
  const [validationSumPayment, setIsValidationSumPayment] = useState({});

  // Результат валидации для поля ввода email
  const [validationEmailField, setValidationEmailField] = useState({});

  // Состояние для управления отображением поля ввода email
  const [isVisibleEmailField, setIsVisibleEmailField] = useState(false);

  // Поля ввода, для которых применяются правила валидации
  const refFieldSum = useRef(),
    refFieldEmail = useRef();

  const {validate} = validateInputFields(),
    {renderError} = useErrorHandler();

  /**
   * При монтировании компонента сумма может удовлетворять условию проверки,
   * но без данной функции кнопка будет некликабельной пока не будет изменено ее значение
   */
  useEffect(() => {
    (async () => {
      await getInitPayment();
      validateSumPayment();
    })();
  }, []);

  /**
   * Получить некоторые параметры необходимые для оплаты
   */
  const getInitPayment = async () => {
    if (IS_FNET_COMPANY) {
      await getAmeriaPaymentInit();
    } else {
      setMinAmount(MIN_PAYMENT_SUM_TINKOFF_BANK);
      setMaxAmount(MAX_PAYMENT_SUM_TINKOFF_BANK);
    }
  };

  /**
   * Получить настройки необходимые для оплаты через AMERIABANK
   */
  const getAmeriaPaymentInit = async () => {
    try {
      const response = await apiFetchAmeriaPaymentInit(contractInfo.contract_id);
      setMinAmount(response.minAmount);
      setMaxAmount(response.maxAmount);
      setCurrency(response.currency);
    } catch (error) {
      setMinAmount(MIN_PAYMENT_SUM_AMERIA_BANK);
      setMaxAmount(MAX_PAYMENT_SUM_AMERIA_BANK);
      setCurrency(locales.am.currencyCode);
    }
  };

  /**
   * Изменить состояние суммы.
   * Также вводимая сумма проверяется на минимально и максимально допустимое значение
   *
   * @param {Object} event
   */
  const changeSumPayment = event => {
    let sum = event.target.value
      .replace(/^0|^[,|.]|[^0-9^,^.]/g, "")
      .replace(/^(\d{1,})[,.]+/g, "$1");

    if (IS_UFANET_COMPANY) {
      sum = event.target.value
        .replace(/^0|^[,|.]|[^0-9^,^.]/g, "")
        .replace(/^(\d{1,}[.|,]{1})[,.]+/g, "$1")
        .replace(/^(\d{1,}[.|,]\d{1})[,.]+/g, "$1")
        .replace(/^(\d{1,}[.|,]\d{2}).+/g, "$1");
    }
    setSumPayment(sum);

    _.debounce(() => {
      validateSumPayment();
    }, DEBOUNCE_DELAY_TIME)();
  };

  /**
   * Проверка на валидность поля суммы
   */
  const validateSumPayment = () => {
    const results = validate([
      {
        type: VALIDATION_RULES.VALID_RANGE_SUM,
        targets: [refFieldSum],
        error: intl.formatMessage(
          {id: "validationError.sum.range"},
          {
            min: getFormattedCurrency(minAmount),
            max: getFormattedCurrency(maxAmount),
          }
        ),
      },
    ]);
    setIsValidationSumPayment(results);
  };

  /**
   * Отобразит/скрыть поле ввода email
   */
  const changeVisibleEmailField = () => {
    setEmail("");
    setValidationEmailField(!validationEmailField.isValid);
    setIsVisibleEmailField(prevState => !prevState);
  };

  /**
   * Изменить значение в поле ввода email
   *
   * @param {Object} event
   */
  const changeEmail = event => {
    const email = event.target.value.replace(/^[,|.]|[а-яА-ЯёЁ]/g, "");
    setEmail(email);

    _.debounce(() => {
      const results = validate([
        {
          type: VALIDATION_RULES.EMPTY_FIELD,
          targets: [refFieldEmail],
          error: "Поле не может быть пустым",
        },
        {
          type: VALIDATION_RULES.VALID_FORMAT_EMAIL,
          targets: [refFieldEmail],
          error: "Неверный формат электронной почты",
        },
      ]);
      setValidationEmailField(results);
    }, DEBOUNCE_DELAY_TIME)();
  };

  /**
   * Получить ссылку на страницу с формой оплаты Тинькофф Банка
   */
  const getPaymentTinkoffUrl = async () => {
    const response = await apiFetchTinkoffPaymentUrl(
      contractInfo.contract_id,
      contractInfo.billing_id,
      sumPayment,
      email
    );

    window.location.href = response.payment_url;
  };

  /**
   * Получить ссылку на страницу с формой оплаты AMERIABANK
   */
  const getPaymentAmeriaUrl = async () => {
    const response = await apiFetchAmeriaPaymentUrl(
      contractInfo.contract_id,
      sumPayment,
      locale,
      currency
    );
    window.location.href = response.data?.url;
  };

  /**
   * Получить ссылку на страницу с формой оплаты Сбера
   */
  const getPaymentSberUrl = async () => {
    const response = await apiFetchSberPaymentUrl(
      contractInfo.contract_id,
      contractInfo.billing_id,
      sumPayment
    );

    window.location.href = response?.detail?.redirect_url;
  };

  /**
   * Перечень всех провайдеров, с помощью которых возможна оплата.
   * Сортировка по возрастанию значения ключа priority:
   * для первого элемента priority будет равен 0
   */
  const getSortedPaymentProviderList = () => {
    if (contractInfo?.payment_providers) return [];
    return [...contractInfo?.payment_providers]?.sort(
      (prev, next) => prev.priority - next.priority
    );
  };

  /**
   * Вызвать метод оплаты провайдера
   *
   * @param {Object} event
   */
  const pay = async event => {
    try {
      event.preventDefault();
      setSpinnerTarget(BUTTON_NAMES.PAYMENT);

      const paymentProviders = getSortedPaymentProviderList();

      if (IS_FNET_COMPANY) {
        await getPaymentAmeriaUrl();
      } else if (paymentProviders[0] === PAYMENT_PROVIDER_LIST.SBER) {
        await getPaymentSberUrl();
      } else {
        await getPaymentTinkoffUrl();
      }
    } catch (error) {
      renderError(error);
    } finally {
      setSpinnerTarget(null);
    }
  };

  const isDisabledPayButton =
    (!validationSumPayment.isValid && !isVisibleEmailField) ||
    (isVisibleEmailField && (!validationSumPayment.isValid || !validationEmailField.isValid));

  return (
    <ModalAlert
      isOpenModal={isOpenModal}
      modalTitle={intl.formatMessage({id: "modalPayment.title"})}
      actionModalClose={closeModal}
    >
      <form onSubmit={pay}>
        <WrapperContainer gap="16">
          <Input
            isStretch
            label={intl.formatMessage({id: "modalPayment.inputSumLabel"})}
            onChange={changeSumPayment}
            value={sumPayment}
            ref={refFieldSum}
            validationErrors={validationSumPayment.errors}
          />
        </WrapperContainer>

        {IS_UFANET_COMPANY && (
          <WrapperContainer gap={isVisibleEmailField ? "16" : "24"}>
            <Checkbox onChange={changeVisibleEmailField} checked={isVisibleEmailField}>
              Получить чек по электронной почте
            </Checkbox>
          </WrapperContainer>
        )}

        {isVisibleEmailField && (
          <WrapperContainer gap="24">
            <Input
              isStretch
              label="E-mail"
              onChange={changeEmail}
              value={email}
              ref={refFieldEmail}
              validationErrors={validationEmailField.errors}
            />
          </WrapperContainer>
        )}

        <div className="buttons-group">
          <Button color="secondary" onClick={closeModal}>
            {intl.formatMessage({id: "btnCancel"})}
          </Button>
          <Button
            isSubmit
            color="primary"
            buttonName={BUTTON_NAMES.PAYMENT}
            disabled={isDisabledPayButton}
          >
            {intl.formatMessage({id: "btnPay"})}
          </Button>
        </div>
      </form>
    </ModalAlert>
  );
};

ModalPayment.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  contractInfo: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalPayment;
