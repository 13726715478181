import React, {useContext, useState} from "react";
import PropTypes from "prop-types";

import WrapperContainer from "../../../components/WrapperContainer/WrapperContainer.jsx";
import ModalAlert from "../../../components/Modal/ModalAlert.jsx";

import Button from "../../../components/Button/Button.jsx";
import BUTTON_NAMES from "../../../components/Button/utils/consts.js";

import apiActivateExtraAction from "../../../api/methods/apiActivateExtraAction.js";
import {renderNotify} from "../../../components/NotifyMessage/utils/renderNotify.js";

import {LoadingContext} from "../../../context";
import EXTRA_ACTIONS from "./utils/consts.js";

import {useErrorHandler} from "../../../utils/hooks/useErrorHandler.js";
import {intl} from "../../../locale";

import {openDocument} from "../../../utils/helpers/openDocument.js";

const AvailableServices = ({services, updateInfo}) => {
  const [selectedAction, setSelectedAction] = useState({}),
    [isOpenModalActivateAction, setIsOpenModalActivateAction] = useState(false);

  const {setSpinnerTarget} = useContext(LoadingContext);
  const {renderError} = useErrorHandler();

  /**
   * Открыть модальное окна для активации доп. действия
   *
   * @param {Number} actionId
   */
  const handleClickService = actionId => {
    const favActions = Object.values(EXTRA_ACTIONS);
    const action = favActions.find(action => action.id === actionId) || {};

    setSelectedAction(action);
    setIsOpenModalActivateAction(true);
  };

  /**
   * Активировать доп. действие
   *
   * @param {Number} actionId
   */
  const activateAction = async actionId => {
    try {
      setSpinnerTarget(BUTTON_NAMES.CONFIRM);
      await apiActivateExtraAction(actionId);

      setIsOpenModalActivateAction(false);
      renderNotify("success", null, intl.formatMessage({id: "apiSuccess.activateExtraAction"}));

      if (actionId === EXTRA_ACTIONS.AVAILABLE_INTERNET.id) {
        openDocument("https://freeinternet.gosuslugi.ru");
      }

      await updateInfo();
    } catch (error) {
      renderError(error);
    } finally {
      setSpinnerTarget(null);
    }
  };

  return (
    <div className="auth-portal-content">
      <div className="available-services">
        {services.map(action => (
          <div
            key={action.id}
            className="available-services__service"
            onClick={() => handleClickService(action.id)}
          >
            <div className="available-services__icon">{action.icon}</div>

            <div className="available-services__text">
              <div className="available-services__text-title">{action.title}</div>
              <div className="available-services__text-desc">{action.description}</div>
            </div>
          </div>
        ))}
      </div>

      {isOpenModalActivateAction && (
        <ModalAlert
          isOpenModal={isOpenModalActivateAction}
          modalTitle={selectedAction.title}
          actionModalClose={() => setIsOpenModalActivateAction(false)}
        >
          {selectedAction.id === EXTRA_ACTIONS.CREDIT.id && (
            <>
              <WrapperContainer gap="16" text="lg">
                Кредит доверия активирует Интернет на 5 дней, а с лицевого счета будет списана сумма
                за этот период (пропорционально абонентской плате).
              </WrapperContainer>

              <WrapperContainer gap="24" text="lg">
                Если оплатите в течение этого периода, то достаточно оплатить сумму за месяц,
                Интернет просто продлится. Если оплатите после окончания кредита доверия, то нужно
                будет заплатить за него и за месяц услуги.
              </WrapperContainer>
            </>
          )}

          {selectedAction.id === EXTRA_ACTIONS.AVAILABLE_INTERNET.id && (
            <>
              <WrapperContainer gap="16" text="lg">
                Услуга позволяет бесплатно пользоваться интернетом для посещения социально-значимых
                сайтов из списка утверждённого правительственной комиссией.
              </WrapperContainer>
              <WrapperContainer gap="24" text="lg">
                Услуга подключается на 24 часа и отключается автоматически по окончании этого
                времени.
              </WrapperContainer>
            </>
          )}

          <div className="buttons-group">
            <Button color="secondary" onClick={() => setIsOpenModalActivateAction(false)}>
              {intl.formatMessage({id: "btnCancel"})}
            </Button>
            <Button
              color="primary"
              onClick={() => activateAction(selectedAction.id)}
              buttonName={BUTTON_NAMES.CONFIRM}
            >
              Подключить
            </Button>
          </div>
        </ModalAlert>
      )}
    </div>
  );
};

AvailableServices.propTypes = {
  services: PropTypes.array.isRequired,
  updateInfo: PropTypes.func.isRequired,
};

export default AvailableServices;
