import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import ModalAlert from "../components/Modal/ModalAlert.jsx";
import WrapperContainer from "../components/WrapperContainer/WrapperContainer.jsx";

import USelect from "../components/Select/USelect.jsx";
import {COMPANY_NAME, IS_FNET_COMPANY} from "../utils/consts.js";
import apiFetchCityList from "../api/methods/apiFetchCityList.js";

import {intl} from "../locale";
import {COMPANIES} from "../vendor.config.js";

const ModalSupport = ({isOpenModal, closeModal}) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});

  useEffect(() => {
    (async function () {
      const cities = await apiFetchCityList();
      const defaultSelectedCity = cities.detail.find(
        city => city.alias === COMPANIES[COMPANY_NAME].support.aliasCity
      ) || {
        short_phone: COMPANIES[COMPANY_NAME].support.phone,
        phone: cities[0]?.phone,
      };

      setCities(cities.detail);
      setSelectedCity(defaultSelectedCity);
    })();
  }, []);

  return (
    <ModalAlert
      isOpenModal={isOpenModal}
      modalTitle={intl.formatMessage({id: "modalSupport.title"})}
      actionModalClose={closeModal}
    >
      {!IS_FNET_COMPANY ? (
        <>
          <WrapperContainer gap="16" text="lg">
            Позвоните нам и наш специалист поможет вам.
          </WrapperContainer>

          {selectedCity.alias && selectedCity.title && (
            <WrapperContainer gap="16">
              <USelect
                isSearchable
                options={cities}
                getOptionValue={option => option.alias}
                getOptionLabel={option => option.title}
                onChange={city => setSelectedCity(city)}
                value={selectedCity}
              />
            </WrapperContainer>
          )}

          <WrapperContainer align="center">
            <a
              href={`tel:${selectedCity.short_phone}`}
              className="link link--default text-phone-number"
            >
              {selectedCity.short_phone}
            </a>
          </WrapperContainer>

          <WrapperContainer gap="16" align="center" text="sm">
            Бесплатно для МТС, Билайн, Мегафон, Теле2.
          </WrapperContainer>

          {selectedCity.phone && (
            <>
              <WrapperContainer align="center">
                <a
                  href={`tel:${selectedCity.phone}`}
                  className="link link--default text-phone-number"
                >
                  {selectedCity.phone}
                </a>
              </WrapperContainer>
              <WrapperContainer text="sm" align="center">
                Для всего остального. По тарифу оператора.
              </WrapperContainer>
            </>
          )}
        </>
      ) : (
        <>
          <WrapperContainer gap="16" text="lg">
            {intl.formatMessage({id: "modalSupport.desc"})}
          </WrapperContainer>

          <WrapperContainer align="center">
            <a
              href={`tel:${selectedCity.short_phone}`}
              className="link link--default text-phone-number"
            >
              {selectedCity.short_phone}
            </a>
          </WrapperContainer>
        </>
      )}
    </ModalAlert>
  );
};

ModalSupport.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalSupport;
