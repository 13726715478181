import React from "react";

import NotifyMessage from "../NotifyMessage.jsx";
import {notifyError, notifySuccess, notifyWarn} from "./notify.js";
import {intl} from "../../../locale";

/**
 * @param {String} type
 * @param {Object|String} title
 * @param {String|JSXElement} content
 */
export function renderNotify(
  type,
  title = "",
  content = intl.formatMessage({id: "apiError.defaultDesc"})
) {
  let _title = title;
  if (typeof title === "object" && title !== null) {
    if (title?.response?.data?.status_id) {
      _title = String(title.response.data.status_id);
    } else if (title?.request) {
      if (title.request.status === 0) {
        _title = title.message;
      } else {
        _title = String(title.request.status);
      }
    } else {
      _title = title.message;
    }
  }

  const notify = <NotifyMessage title={_title}>{content}</NotifyMessage>;

  switch (type) {
    case "success":
      return notifySuccess(notify);
    case "warn":
      return notifyWarn(notify);
    case "error":
    default:
      return notifyError(notify);
  }
}
