import React from "react";
import {toast} from "react-toastify";
import Icon from "../../Icon/Icon.jsx";

/**
 * Общие настройки для уведомлений всех типов
 * Настройки [тут]{@link(https://fkhadra.github.io/react-toastify/introduction)}
 */
const options = {
  position: "bottom-left",
  theme: "colored",
  autoClose: 15000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

/**
 * Уведомление для отображения ошибки
 *
 * @param {String|JSX.Element} message
 */
export const notifyError = message => {
  toast.error(message, {
    ...options,
    icon: () => <Icon name="alert-error" color="#fff" />,
  });
};

/**
 * Уведомление для отображения предупреждения
 *
 * @param {String|JSX.Element} message
 */
export function notifyWarn(message) {
  toast.warn(message, {
    ...options,
    icon: () => <Icon name="alert-warn" color="#fff" />,
  });
}

/**
 * Уведомление на случай если действие выполнено успешно
 *
 * @param {String|JSX.Element} message
 */
export function notifySuccess(message) {
  toast.success(message, {
    ...options,
    icon: () => <Icon name="alert-success" color="#fff" />,
  });
}
