import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";

import Placeholder from "../../components/Placeholder.jsx";
import Button from "../../components/Button/Button.jsx";

import ModalAddContract from "../ModalAddContract.jsx";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer.jsx";

import apiFetchContract from "../../api/methods/apiFetchContract.js";
import {useErrorHandler} from "../../utils/hooks/useErrorHandler.js";

import {intl} from "../../locale";
import {IS_UFANET_COMPANY} from "../../utils/consts.js";
import {UpdateInfoContext} from "../../context";

const Authorized = () => {
  const {renderError} = useErrorHandler();
  const {setIsVisibleAuthButton} = useContext(UpdateInfoContext);

  const [contractMainInfo, setContractMainInfo] = useState({});
  const [isOpenModalAddContract, setIsOpenModalAddContract] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await apiFetchContract();
        const {contract_title, contract_id, billing_id, balance_info} = response.detail;

        setContractMainInfo({
          contract_title,
          contract_id,
          billing_id,
          balance_info,
        });
      } catch (error) {
        renderError(error);
      }
    })();

    setIsVisibleAuthButton(true);
  }, []);

  if (_.isEmpty(contractMainInfo)) {
    return <Placeholder />;
  }

  return (
    <div className="auth-portal-content">
      <div className="auth-portal-content__title">
        {intl.formatMessage({id: "modalAuthorized.title"})}
      </div>
      <div className="auth-portal-content__content">
        <WrapperContainer gap={IS_UFANET_COMPANY ? "24" : ""} text="lg">
          {intl.formatMessage(
            {id: "modalAuthorized.desc"},
            {contractTitle: <b>{contractMainInfo.contract_title}</b>}
          )}
        </WrapperContainer>

        {IS_UFANET_COMPANY && (
          <Button
            isStretch
            color="secondary"
            size="xxl"
            onClick={() => setIsOpenModalAddContract(true)}
          >
            Оформить новый договор
          </Button>
        )}
      </div>

      {isOpenModalAddContract && (
        <ModalAddContract
          isOpenModal={isOpenModalAddContract}
          closeModal={() => setIsOpenModalAddContract(false)}
        />
      )}
    </div>
  );
};

export default Authorized;
