import Bowser from "bowser";

/**
 * Открыть модальное окно в новой вкладке
 *
 * @param {String} url
 */
export function openDocument(url) {
  const link = window.document.createElement("a");
  const userAgent = Bowser.parse(window.navigator.userAgent);

  link.href = url;
  if (userAgent.browser.name.toLowerCase() === "safari" && userAgent.platform.type === "mobile") {
    link.target = "_parent";
  } else {
    link.target = "_blank";
  }

  window.document.body.appendChild(link);
  link.click();
  link.remove();
}
