import {requestConfig} from "../config.js";

/**
 * Активировать доп. действие
 *
 * @param {Number} actionId
 */
export default async function apiActivateExtraAction(actionId) {
  await requestConfig.post("api/v2/user/activate_action/", {
    id_action: actionId,
  });
}
