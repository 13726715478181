import {createIntl, createIntlCache} from "react-intl";

import English from "./messages/en.json";
import Russian from "./messages/ru.json";

export const locale = process.env.REACT_APP_LANG_DEFAULT;
export const locales = {
  am: {
    name: "hy",
    currency: "AMD",
    currencyCode: "051",
  },
  ru: {
    name: "ru",
    currency: "RUB",
    currencyCode: "643",
  },
};

let messages = null;
switch (locale) {
  case "en":
    messages = English;
    break;
  case "ru":
  default:
    messages = Russian;
}

export const intl = createIntl(
  {locale: locale, defaultLocale: locales.ru.name, messages: messages},
  createIntlCache()
);
