import {requestConfig} from "../../config.js";
import version from "../../../../package.json";

/**
 * Получить ссылку на страницу оплаты
 *
 * @param {Number} contractId
 * @param {Number} sumPayment
 * @param {String} language
 * @param {String} currency
 */
export default async function apiFetchAmeriaPaymentUrl(contractId, sumPayment, language, currency) {
  try {
    const response = await requestConfig.post("ameriabank/public/api/v0/processing", {
      amount: sumPayment,
      contractId: contractId,
      callbackUrl: `${window.location.href}?isPaid=true`,
      channelId: "WEB",
      channelVersion: version,
      currency: currency,
      language: language,
    });

    return response;
  } catch (error) {
    throw error;
  }
}
