import React from "react";
import PropTypes from "prop-types";

import Error from "./Screens/Error.jsx";
import WrapperContainer from "../components/WrapperContainer/WrapperContainer.jsx";
import {COMPANIES} from "../vendor.config.js";

/**
 * Универсальный компонент на случай если произойдет
 * непредвиденная ошибка на сервере или есть иная ошибка в коде
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    console.error(`${error.message}, ${info.componentStack}`);
  }

  render() {
    /**
     * При наличии логических ошибок в коде сработает состояние hasError,
     * в случае если какой-либо запрос к API выполнен не будет, то сработает hasApiError
     */
    if (this.state.hasError || this.props.hasApiError) {
      return (
        <div className="auth-portal-container">
          <WrapperContainer gap="16" align="center">
            <img
              src={COMPANIES[process.env.REACT_APP_VENDOR].imgs.logoPrimary}
              className="logo-company"
              alt=""
            />
          </WrapperContainer>
          <Error />
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  hasApiError: PropTypes.bool,
  children: PropTypes.element,
};

ErrorBoundary.defaultProps = {
  hasApiError: false,
  children: null,
};
