import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {YMInitializer} from "react-yandex-metrika";

import {CSSTransition} from "react-transition-group";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AuthContainer from "./features/AuthContainer/AuthContainer.jsx";
import ModalSupport from "./features/ModalSupport.jsx";

import Button from "./components/Button/Button.jsx";
import ErrorBoundary from "./features/ErrorBoundary.jsx";

import Icon from "./components/Icon/Icon.jsx";
import {ApiErrorContext, StatusConnectionContext, LoadingContext} from "./context";

import apiFetchStatusConnection from "./api/methods/apiFetchStatusConnection.js";
import {renderNotify} from "./components/NotifyMessage/utils/renderNotify.js";

import {useErrorHandler} from "./utils/hooks/useErrorHandler.js";
import {COMPANY_NAME, IS_UFANET_COMPANY} from "./utils/consts.js";

import {CloseButton} from "./components/NotifyMessage/NotifyMessage.jsx";
import {intl} from "./locale";
import {COMPANIES} from "./vendor.config.js";

const App = () => {
  const navigate = useNavigate();
  const {renderError} = useErrorHandler();

  const [statusConnection, setStatusConnection] = useState(null);
  const [isOpenModalSupport, setIsOpenModalSupport] = useState(false);

  const [hasApiError, setHasApiError] = useState(false);
  /**
   * Специальный флаг для работы анимации кнопки поддержки.
   * Для работы анимации можно привязаться и на состояние statusConnection,
   * но в случае если apiFetchStatusConnectionUser выполнить не удастся,
   * анимация не сработает.
   */
  const [isMounted, setIsMounted] = useState(false);
  const [spinnerTarget, setSpinnerTarget] = useState(null);

  useEffect(() => {
    document.body.parentElement.className = `theme-${COMPANIES[COMPANY_NAME].theme}`;
    setIsMounted(true);

    (async function () {
      try {
        const statusConnection = await apiFetchStatusConnection();
        setStatusConnection(statusConnection.status_id);
      } catch (error) {
        renderError(error);
      }
    })();
  }, []);

  useEffect(() => {
    document.title = intl.formatMessage(
      {id: "homePage.title"},
      {companyName: COMPANIES[process.env.REACT_APP_VENDOR].description.labelFull}
    );
    afterPaymentHandler();
  }, [window.location.href]);

  const afterPaymentHandler = () => {
    const urlParams = new URLSearchParams(window.location.search);

    // Для отображения нужного типа уведомления используется get-параметр isPaid
    if (urlParams.has("isPaid")) {
      if (urlParams.get("isPaid") === "true") {
        renderNotify(
          "success",
          intl.formatMessage({id: "apiSuccess.payment"}),
          <>
            {intl.formatMessage({id: "apiSuccess.paymentExtra"})}
            <Button color="primary" onClick={() => window.location.reload()}>
              {intl.formatMessage({id: "btnRefreshPage"})}
            </Button>
          </>
        );
      }

      if (urlParams.get("isPaid") === "false") {
        renderNotify(
          "error",
          "Ошибка при оплате",
          "Возможно данные карты были указаны неверно или не хватило денег. Попробуйте еще раз"
        );
      }

      /**
       * Удалить параметры из адресной строки для того,
       * чтобы при обновлении уведомление не было показано вновь
       */
      setTimeout(() => {
        navigate("/", {replace: true});
      }, 1000);
    }
  };

  const openModalSupport = () => setIsOpenModalSupport(true);
  const closeModalSupport = () => setIsOpenModalSupport(false);

  const classNames = ["app-content", spinnerTarget && "app-content--not-available"]
    .filter(name => name)
    .join(" ");

  return (
    <div className={classNames}>
      <LoadingContext.Provider value={{spinnerTarget, setSpinnerTarget}}>
        <ApiErrorContext.Provider value={setHasApiError}>
          <ErrorBoundary hasApiError={hasApiError}>
            <StatusConnectionContext.Provider value={setStatusConnection}>
              <AuthContainer statusConnection={statusConnection} />
            </StatusConnectionContext.Provider>
          </ErrorBoundary>
        </ApiErrorContext.Provider>

        <>
          <CSSTransition in={isMounted} timeout={300}>
            <Button size="sm" className="support-float-button" onClick={openModalSupport}>
              <Icon isAccent name="headset" />
              {intl.formatMessage({id: "btnSupport"})}
            </Button>
          </CSSTransition>

          {isOpenModalSupport && (
            <ModalSupport isOpenModal={isOpenModalSupport} closeModal={closeModalSupport} />
          )}
        </>

        <ToastContainer closeButton={CloseButton} />
      </LoadingContext.Provider>

      {IS_UFANET_COMPANY && (
        <YMInitializer
          accounts={[COMPANIES[process.env.REACT_APP_VENDOR].yaCounter]}
          options={{clickmap: true, webvisor: true}}
        />
      )}
    </div>
  );
};

export default App;
