import React from "react";
import PropTypes from "prop-types";

import Icon from "../Icon/Icon.jsx";
import Button from "../Button/Button.jsx";

import "./styles/style.scss";

// eslint-disable-next-line react/prop-types
export const CloseButton = ({closeToast}) => {
  return (
    <Button icon="only" paddingOff="all" onClick={closeToast}>
      <Icon name="close" color="#fff" />
    </Button>
  );
};

/**
 * Содержимое уведомлений.
 * Самое уведомление отображается с помощью специального компонента ToastContainer,
 * он импортирован в компонент App.
 *
 * Управление отображением ToastContainer происходит
 * с помощью 3х функций определенных в notifications
 *
 * @param {String} title
 */
const NotifyMessage = ({title, ...rest}) => {
  return (
    <div className="notify-message">
      {title && <div className="notify-message__title">{title}</div>}
      <div className="notify-message__content">{rest.children}</div>
    </div>
  );
};

NotifyMessage.propTypes = {
  title: PropTypes.string,
};

NotifyMessage.defaultProps = {
  title: "",
};

export default NotifyMessage;
