import {useContext} from "react";

import {ApiErrorContext} from "../../context";
import {renderNotify} from "../../components/NotifyMessage/utils/renderNotify.js";

export function useErrorHandler() {
  const setHasApiError = useContext(ApiErrorContext);

  /**
   * @param {Object} error
   */
  const renderError = error => {
    if (error.response?.status_id || error.request?.status) {
      renderNotify("error", error);
    } else {
      setHasApiError(true);
    }
  };

  return {renderError};
}
